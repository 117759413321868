@import "react-phone-number-input/style.css";

root {
  --antd-control-height: 22px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.small-padding-dropdown.ant-select-dropdown .ant-select-item {
  padding: 3px;
}

.with-action {
  cursor: pointer;
  color: var(--ant-color-primary);
}

.with-action:hover {
  color: var(--ant-color-primary-hover);
}

.rounded-lg {
  border-radius: 0.5rem;
}

.highlight-red2 {
  background: var(--ant-red-2);
}

.ant-table-wrapper .highlight-red2 td.ant-table-column-sort {
  background: var(--ant-red-2);
}

/*phone input*/

.phone-input__input {
  background: var(--ant-color-bg-container);
  border-width: var(--ant-line-width);
  border-style: var(--ant-line-type);
  border-color: var(--ant-color-border);
  box-sizing: border-box;
  margin: 0;
  padding-left: 0.75rem;
  color: var(--ant-color-text);
  font-size: var(--ant-input-input-font-size);
  line-height: var(--ant-line-height);
  list-style: none;
  font-family: var(--ant-font-family);
  position: relative;
  width: 100%;
  min-width: 0;
  border-radius: var(--ant-border-radius);
  transition: all var(--ant-motion-duration-mid);
  height: 32px;
}

.phone-input__input:hover {
  border-color: var(--ant-input-hover-border-color);
  background-color: var(--ant-input-hover-bg);
}

.phone-input__input:focus {
  border-color: var(--ant-input-active-border-color);
  box-shadow: var(--ant-input-active-shadow);
  outline: 0;
  background-color: var(--ant-input-active-bg);
}

.phone-input__input.disabled {
  color: var(--ant-color-text-disabled);
  background-color: var(--ant-color-bg-container-disabled);
  border-color: var(--ant-color-border);
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.PhoneInputInput {
  border: none;
  outline: none;
  height: calc(100% - 2px);
  width: calc(100% - 5px);
  margin-left: 5px;
  border-radius: 4px;
}

.phone-input__input.disabled .PhoneInputInput {
  color: var(--ant-color-text-disabled);
  background-color: transparent;
  border-color: var(--ant-color-border);
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}

.PhoneInputCountrySelect {
  display: none;
}

.PhoneInputCountrySelectArrow {
  display: none;
}

.native-min-height.ant-form-item .ant-form-item-control-input {
  min-height: inherit;
}

.input-number-center.ant-input-number .ant-input-number-input {
  text-align: center;
}

.input-label-without-overflow.ant-form-item .ant-form-item-label {
  overflow: inherit;
}

.tox-statusbar__branding {
  display: none;
}

@media print {
  .pdf-hide,
  .ant-layout-header {
    display: none;
  }
}
